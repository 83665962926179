<template>
  <page :title="`${cuadroMando.nombre}: asignación de indicadores`">
    <b-alert show variant="warning">
      Arrastre los indicadores de la derecha, dentro de los bloques donde desea que se muestren.
    </b-alert>
    <br />
    <form @submit.prevent="guardarDisenio">
      <b-row class="contendor-central">
        <b-col cols="12" md="8">
          <div>
            <h4>Disposición {{ cuadroMando.disposicion }}</h4>
            <div class="contenedor-bloques" :class="{ [`disposicion-${cuadroMando.disposicion}`]: true }">
              <b-card
                v-for="(bloque, index) in [1, 2, 3]"
                :key="index"
                class="bloque"
                @dragenter="entrando(arguments[0], bloque)"
                @dragleave="saliendo"
                @dragover="dragoverEnBloque"
                @drop="soltar(arguments[0], bloque)"
                :class="{ activo: bloqueActivo(bloque) }"
              >
                <div
                  v-for="(elemento, index) in indicadoresEnBloque(bloque)"
                  :key="index"
                  class="elemento"
                  :class="{ rezagado: esRezagado(elemento.indicadorId) }"
                >
                  <abbr-naturaleza :rezagado="esRezagado(elemento.indicadorId)" />

                  <span class="nombre-indicador">{{ nombreIndicador(elemento.indicadorId) }}</span>

                  <span class="mini-toolbar">
                    <span @click="quitar(elemento)">
                      <v-icon name="trash-2" width="16"></v-icon>
                    </span>
                  </span>
                </div>
                <div class="drop-area" :class="{ activo: bloqueActivo(bloque) }">Arrastre uno o más indicadores</div>
              </b-card>
            </div>
          </div>
        </b-col>

        <b-col cols="12" md="4">
          <div class="contenedor-indicadores">
            <h4>Indicadores</h4>
            <b-card>
              <b-form-group>
                <b-form-input type="search" v-model="filtro" placeholder="Escriba aquí..."></b-form-input>
              </b-form-group>
              <b-alert :show="hayMasIndicadores" variant="warning"> Hay más indicadores, refine la búsqueda </b-alert>
              <br />
              <div
                class="indicador"
                :class="{ rezagado: esRezagado(indicador.id) }"
                v-for="indicador in indicadoresLimitados"
                :key="indicador.id"
                draggable="true"
                @dragstart="coger(arguments[0], indicador.id)"
              >
                <abbr-naturaleza :rezagado="esRezagado(indicador.id)" />
                <span class="nombre-indicador">{{ indicador.nombre }}</span>
              </div>
              <div v-if="hayMasIndicadores" title="Hay más indicadores, refine la búsqueda" class="text-center">
                &hellip;
              </div>
            </b-card>
          </div>
        </b-col>
      </b-row>

      <botonera-edicion url-cancelar="/administracion/cuadros-de-mando"> </botonera-edicion>
    </form>
  </page>
</template>
<script>
import * as apiIndicadores from "@/services/indicadores.js";
import * as apiCuadrosMando from "@/services/cuadrosMando.js";
import * as NaturalezaIndicador from "@/enum/NaturalezaIndicador.js";
import AbbrNaturaleza from "./AbbrNaturaleza.vue";

const NUMROWS = 15;

function mismoElemento(a, b) {
  return a.indicadorId == b.indicadorId && a.bloque == b.bloque && a.orden == b.orden;
}

function cachear(col, field) {
  return col.reduce(function (rv, item) {
    rv[item.id] = item[field];
    return rv;
  }, {});
}

export default {
  order: 1,
  components: {
    AbbrNaturaleza,
  },
  data() {
    return {
      indicadores: [],
      cache_nombres: {},
      cache_naturalezas: {},
      cuadroMando: {},
      disenio: [],
      filtro: "",
      seleccionado: {
        indicadorId: null,
        bloque: null,
      },
    };
  },
  computed: {
    enUso() {
      return this.disenio.map((x) => x.indicadorId);
    },
    indicadoresEncontrados() {
      const filtro = this.filtro.toLowerCase();
      const filtrados = this.indicadores.filter((x) => x.nombre.toLowerCase().indexOf(filtro) > -1);
      return filtrados;
    },
    hayMasIndicadores() {
      return this.indicadoresEncontrados.length > NUMROWS;
    },
    indicadoresLimitados() {
      if (this.hayMasIndicadores) {
        return this.indicadoresEncontrados.slice(0, NUMROWS);
      } else {
        return this.indicadoresEncontrados;
      }
    },
  },
  methods: {
    nombreIndicador(indicadorId) {
      return this.cache_nombres[indicadorId];
    },
    indicadoresEnBloque(bloque) {
      return this.disenio.filter((x) => x.bloque == bloque);
    },
    async cargarCuadroMando(id) {
      this.cuadroMando = await apiCuadrosMando.cargar(id);
    },
    async cargarDisenio(id) {
      const response = await apiCuadrosMando.cargarDisenio(id);
      this.disenio = response.disenio;
    },
    async guardarDisenio() {
      await apiCuadrosMando.guardarDisenio(this.cuadroMando.id, this.disenio);
      this.$emit("userMessage", "Se ha guardado el diseño");
      this.$router.push({ path: "/cuadro-mando" });
    },
    async cargarIndicadores() {
      this.indicadores = await apiIndicadores.listar();
      this.cache_nombres = cachear(this.indicadores, "nombre");
      this.cache_naturalezas = cachear(this.indicadores, "naturaleza");
    },
    coger(event, indicadorId) {
      this.seleccionado.indicadorId = indicadorId;
      this.seleccionado.bloque = null;
    },
    entrando(event, bloque) {
      if (this.seleccionado.indicadorId) {
        this.seleccionado.bloque = bloque;
      }
    },
    saliendo(event) {
      if (this.seleccionado.indicadorId) {
        this.seleccionado.bloque = null;
      }
    },
    soltar(event, bloque) {
      if (this.seleccionado.indicadorId) {
        const indicadorId = this.seleccionado.indicadorId;
        const orden = this.determinarOrden(bloque);
        this.disenio.push({ bloque, indicadorId, orden });
        this.seleccionado.indicadorId = null;
        this.seleccionado.bloque = null;
      }
    },
    dragoverEnBloque(event) {
      event.preventDefault();
      event.dataTransfer.dropEffect = "copy";
    },
    bloqueActivo(bloque) {
      return this.seleccionado.bloque == bloque;
    },
    moviendoIndicador(indicadorId) {
      return this.seleccionado.indicadorId == indicadorId;
    },
    quitar(elemento) {
      this.disenio = this.disenio.filter((x) => {
        return !mismoElemento(x, elemento);
      });
    },
    determinarOrden(bloque, indicadorId) {
      return (
        this.disenio.filter((x) => {
          return x.bloque == bloque;
        }).length + 1
      );
    },
    esRezagado(indicadorId) {
      return this.cache_naturalezas[indicadorId] == NaturalezaIndicador.REZAGADO;
    },
  },
  async mounted() {
    await this.cargarIndicadores();
    this.cargarCuadroMando(this.$route.params.id);
    this.cargarDisenio(this.$route.params.id);
  },
};
</script>

<style scoped>
.contendor-central h4 {
  text-align: center;
}
.contenedor-indicadores {
}
.contenedor-bloques {
  display: grid;
}
.bloque {
  padding: 0.5em;
  margin: 0 0.5em 1em 0.5em;
  padding-bottom: 1em;
}

.bloque.activo {
  color: white;
  background-color: #e3eed760;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.bloque.activo * {
  pointer-events: none;
}

.indicador,
.elemento {
  padding: 0.5em 1em;
  margin: 0.5em;
  user-select: none;

  color: #3b571c;
  background-color: #e3eed7;
  border: 1px solid #d7e7c7;
}

.indicador {
  cursor: grab;
}

.indicador:active {
  cursor: grabbing;
  opacity: 0.7;
}

.elemento.rezagado,
.indicador.rezagado {
  color: #3b571c;
  background-color: #e3eed7;
  border: 1px solid #d7e7c7;
}

.nombre-indicador {
  display: inline-block;
  vertical-align: middle;
}

.abbr {
  display: inline-block;
  vertical-align: middle;
  font-style: italic;
  color: darkred;
  padding-right: 1em;
  font-size: 0.87em;
}

.abbr.rezagado {
  color: orange;
}

.drop-area {
  text-align: center;
  color: black;
  opacity: 0.5;
  user-select: none;
}
.drop-area.activo {
  opacity: 1;
}
.mini-toolbar {
  float: right;
  cursor: pointer;
}
.disposicion-1 > div {
  display: none;
}

.disposicion-1 .bloque:first-child {
  display: block;
}

.disposicion-2 .bloque:last-child {
  grid-column: 1 / span 2;
}

.disposicion-3 .bloque:first-child {
  grid-column: 1 / span 2;
}
</style>
