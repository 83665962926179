<template>
  <span class="abbr" :class="{ rezagado: rezagado }">
    <span title="Indicador rezagado" v-if="rezagado">Re</span>
    <span title="Indicador adelantado" v-else>Ad</span>
  </span>
</template>
<script>
export default {
  props: {
    rezagado: Boolean,
  },
};
</script>
